import React from 'react';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../../theme';

const useStyles = makeStyles(theme => ({
    paymentIcon: {
        height: '18px',
        width: '28px',
        marginRight: theme.spacing(1),
        borderRadius: 'none',
    },
}));

export default function PaymentLogos() {
    const classes = useStyles(theme);

    return (
        <React.Fragment>
            <Icon>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className={classes.paymentIcon} src="/payment-logos/visa-logo.svg"/>
            </Icon>
            <Icon>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className={classes.paymentIcon} src="/payment-logos/mastercard.svg"/>
            </Icon>
            <Icon>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className={classes.paymentIcon} src="/payment-logos/amex.svg"/>
            </Icon>
            <Icon>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className={classes.paymentIcon} src="/payment-logos/maestro.svg"/>
            </Icon>
            <Icon>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className={classes.paymentIcon} src="/payment-logos/apple-pay.svg"/>
            </Icon>
            <Icon>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className={classes.paymentIcon} src="/payment-logos/google-pay.svg"/>
            </Icon>
            <Icon>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className={classes.paymentIcon} src="/payment-logos/klarna.svg"/>
            </Icon>
            <Icon>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className={classes.paymentIcon} src="/payment-logos/sofort.svg"/>
            </Icon>
        </React.Fragment>
    );
}
