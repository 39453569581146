import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useRouter } from 'next/router';

import Footer from './footer';
import theme from '../../theme';
import Divider from '../divider';
import BottomNavigation from './bottomNavigation';
import DesktopNavigation from './desktopNavigation';
import NewsletterRegistration from '../newsletterRegistration';
import MovingBanner from '../movingBanner';
import getTranslatedContent from '../../staticPageScripts/getTranslatedContent';

export default function Layout ({ children, shopMenus, }) {
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const router = useRouter();
    const movingBanners = getTranslatedContent('movingBanners', router.locale);

    return (
        <React.Fragment>
            {isDesktop && <DesktopNavigation menus={shopMenus} />}
            {!isDesktop && movingBanners && <MovingBanner {...movingBanners} />}
            {children}
            <Divider />
            {!isMobile && <br />}
            <NewsletterRegistration isMobile={isMobile} showText={true} />
            <Footer isMobile={isMobile} isTablet={isTablet} menus={shopMenus}/>
            {!isDesktop && <BottomNavigation menus={shopMenus}/>}
        </React.Fragment>
    );
}
