import React from 'react';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import smoothscroll from 'smoothscroll-polyfill';

import theme from '../src/theme';
import Layout from '../src/components/layout';
import { Provider } from '../src/context/appContext';
import ShopifySync from '../src/containers/shopifySync';
import ConsentModal from '../src/containers/consentModal';
import NewsletterModal from '../src/components/newsletterModal';
import getTranslatedContent from '../src/staticPageScripts/getTranslatedContent';

import '../src/styles.css';

export default function TheCodeApp(props) {
    const router = useRouter();
    const [showNewsletterModal, setShowNewsletterModal] = React.useState(false);

    const { Component, pageProps } = props;
    const shopMenus = getTranslatedContent('shopMenus', router.locale);
    const newsletterModal = getTranslatedContent('newsletterModal', router.locale);

    // add translated content to app
    pageProps.translatedContent = {
        de: {},
        en: {}
    };

    pageProps.locale = router.locale;

    const seoTitle = pageProps.seoTitle;
    const seoDescription = pageProps.seoDescription;

    const showNewsletterModalHandler = () => {
        // 1. check if the user already has closed the consent modal
        const consent = window.localStorage.getItem('consent');
        // 2. check if the user did not already close the newsletter modal
        const newsletterModalClosed = window.localStorage.getItem('newsletterModalClosed');
        // 3. if not, show the modal
        if (consent && !newsletterModalClosed) {
            setShowNewsletterModal(true);
        }
    };

    /**
     * Checks if there is a anchor link - if yes, scroll to it.
     * Otherwise, scroll to the top of the page.
     */
    React.useEffect(() => {
        const handleRouteChange = () => {
            try {
                const path = window.location.hash;
                if (path && path.includes('#')) {
                    setTimeout(() => {
                        window.scrollBy(0, -70);
                    }, 750);
                } else {
                    setTimeout(() => {
                        window.scrollTo(1, 1);
                    }, 300);
                }
            } catch (error) {
                // console.error(error);
            }
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    React.useEffect(() => {
        smoothscroll.polyfill();
        window.__forceSmoothScrollPolyfill__ = true;

        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    // check if we can show the newsletter modal
    React.useEffect(() => {
        try {
            if (newsletterModal && Object.keys(newsletterModal).length > 0 && newsletterModal.active) {
                const interval = setInterval(() => {
                    showNewsletterModalHandler();
                }, 30000);
                return () => clearInterval(interval);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <React.Fragment>
            <Provider locale={router.locale}>
                <Head>
                    <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                </Head>
                <NextSeo title={seoTitle} description={seoDescription} />
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    {/* Used to sync the local storage with shopify */}
                    <ShopifySync />
                    <Layout shopMenus={shopMenus}>
                        <Component {...pageProps} />
                    </Layout>
                    <ConsentModal />
                    {showNewsletterModal && <NewsletterModal open={showNewsletterModal} {...newsletterModal} />}
                </ThemeProvider>
            </Provider>
        </React.Fragment>
    );
}
