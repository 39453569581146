/* eslint-disable complexity */
import shopDataDe from '../content/de/shopData.json';
import translationsDe from '../content/de/translations';
import pageAndBlogDataDe from '../content/de/pageAndBlogData';
import policiesDe from '../content/de/policies';
import quizDataDe from '../content/de/quizData';
import quizDataTestDe from '../content/de/quizData-test';
import shopMenusDe from '../content/de/shopMenus';
import shopProductsDe from '../content/de/shopProducts';
import movingBannersDe from '../content/de/movingBanners';
import newsletterModalDe from '../content/de/newsletterModal';
import quizFinishContentDe from '../content/de/quizFinishContent';
import dynamicContentDe from '../content/de/dynamicContent';

// import shopDataEn from '../content/en/shopData';
// import translationsEn from '../content/en/translations';
// import pageAndBlogDataEn from '../content/en/pageAndBlogData';
// import policiesEn from '../content/en/policies';
// import quizDataEn from '../content/en/quizData';
// import quizDataTestEn from '../content/en/quizData-test';
// import shopMenusEn from '../content/en/shopMenus';
// import shopProductsEn from '../content/en/shopProducts';
// import movingBannersEn from '../content/en/movingBanners';
// import newsletterModalEn from '../content/en/newsletterModal';
// import quizFinishContentEn from '../content/en/quizFinishContent';
// import dynamicContentEn from '../content/en/dynamicContent';

const getTranslatedContent = (filename, locale) => {
    // if (locale === 'en') {
    //     switch (filename) {
    //         case 'shopData':
    //             return shopDataEn;
    //         case 'translations':
    //             return translationsEn;
    //         case 'pageAndBlogData':
    //             return pageAndBlogDataEn;
    //         case 'policies':
    //             return policiesEn;
    //         case 'quizData':
    //             return quizDataEn;
    //         case 'quizData-test':
    //             return quizDataTestEn;
    //         case 'shopMenus':
    //             return shopMenusEn;
    //         case 'shopProducts':
    //             return shopProductsEn;
    //         case 'movingBanners':
    //             return movingBannersEn;
    //         case 'newsletterModal':
    //             return newsletterModalEn;
    //         case 'quizFinishContent':
    //             return quizFinishContentEn;
    //         case 'dynamicContent':
    //             return dynamicContentEn;
    //         default:
    //             break;
    //     }
    // }
    if (locale === 'de') {
        switch (filename) {
            case 'shopData':
                return shopDataDe;
            case 'translations':
                return translationsDe;
            case 'pageAndBlogData':
                return pageAndBlogDataDe;
            case 'policies':
                return policiesDe;
            case 'quizData':
                return quizDataDe;
            case 'quizData-test':
                return quizDataTestDe;
            case 'shopMenus':
                return shopMenusDe;
            case 'shopProducts':
                return shopProductsDe;
            case 'movingBanners':
                return movingBannersDe;
            case 'newsletterModal':
                return newsletterModalDe;
            case 'quizFinishContent':
                return quizFinishContentDe;
            case 'dynamicContent':
                return dynamicContentDe;
            default:
                break;
        }
    }
};

export default getTranslatedContent;
