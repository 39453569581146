import React from 'react';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import theme from '../theme';

export default function ScrollToTopBox () {
    const style = {
        height: `${theme.spacing(6)}px`,
        width: `${theme.spacing(6)}px`,
        backgroundColor: theme.palette.footer.background,
        border: '2px solid white',
        position: 'absolute',
        right: '10%',
        bottom: theme.spacing(2),
        cursor: 'pointer',

    };

    const scrollTopTopHandler = () => {
        window.scrollTo({
            top: 1,
            behavior: 'smooth',
        });
    };

    if (!style) return null;

    return (
        <div style={{ position: 'relative', float: 'unset', }}>
            <div style={style}>
                <div style={{ margin: 'auto', textAlign: 'center', }} onClick={scrollTopTopHandler}>
                    <KeyboardArrowUpIcon style={{ fontSize: '2rem', marginTop: '5px', color: 'white', }} />
                </div>
            </div>
        </div>
    );
}
