import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button } from '@material-ui/core';

import theme from '../theme';

const useStyles = makeStyles(theme => ({
    paper: {
        overflow: 'auto',
        outline: 'none !important',
        backgroundColor: theme.palette.background.paper,
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(3),
        border: '2px solid black',
        padding: 0,
        zIndex: 100000,
        backgroundColor: 'inherit',
    },
}));

export default function ModalComponent({
    open,
    body,
    handleClose,
    modalHeight = '98vh',
    modalWidth = '99vw',
    margin = undefined,
}) {
    const classes = useStyles(theme);

    if (!margin) margin = theme.spacing(1, 1);

    React.useEffect(() => {
        if (open) {
            document.body.style.position = 'fixed';
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.documentElement.overflow = 'hidden';
        } else {
            document.body.style.position = 'relative';
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
            document.documentElement.overflow = 'auto';
        }
        return function cleanup() {
            document.body.style.position = 'relative';
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
            document.documentElement.overflow = 'auto';
        };
    }, [open]);

    const bodyWrapper = (
        <div className={classes.paper} style={{ height: modalHeight, width: modalWidth, margin, }}>
            <Button className={classes.closeButton} onClick={handleClose}>
                <strong>X</strong>
            </Button>
            {body}
        </div>
    );

    return (
        <React.Fragment>
            <Modal open={open} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                {bodyWrapper}
            </Modal>
        </React.Fragment>
    );
}
