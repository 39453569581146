import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

export default function ErrorSnackbar ({ errorMessage, handleClose, autoHideDuration = 5000, }) {
    return <Snackbar
        open
        onClose={handleClose}
        key={'top' + 'right'}
        autoHideDuration={autoHideDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
    >
        <Alert severity="error">{errorMessage}</Alert>
    </Snackbar>;
}
