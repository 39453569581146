import React from 'react';
import NextLink from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Container, List, Grid, SvgIcon } from '@material-ui/core';
import { useRouter } from 'next/router';
import ShoppingBag from '@mui/icons-material/ShoppingCart';

import { Context } from '../../context/appContext';

import theme from '../../theme';
import CustomMenuItem from './customMenuItem';
import getTranslatedContent from '../../staticPageScripts/getTranslatedContent';
import MovingBanner from '../movingBanner';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	appBar: {
		backgroundColor: theme.palette.navigation.background,
		color: theme.palette.navigation.textColor
	},
	toolBar: {
		padding: 0
	},
	title: {
		position: 'absolute',
		top: '25%'
	},
	listStyle: {
		padding: 0,
		display: 'flex',
		flexDirection: 'row',
		float: 'right'
	},
	menuItem: {
		padding: '1rem',
		lineHeight: '2rem',
		fontSize: '0.9rem',
		'&:hover': {
			backgroundColor: 'inherit'
		}
	},
	icons: {
		right: 0,
		bottom: '30%',
		position: 'absolute'
	},
	logoWrapper: {
		height: '100%',
		display: 'inline-block',
		verticalAlign: 'middle'
	},
	logo: {
		maxHeight: '1.5em',
		verticalAlign: 'middle'
	}
}));

export default function DesktopNavigation({ menus }) {
	const classes = useStyles(theme);
	const menu = menus.siteMenus.find((m) => m.name.toLowerCase().includes('main')).fields;
	const router = useRouter();
	const movingBanners = getTranslatedContent('movingBanners', router.locale);

	const { state } = React.useContext(Context);
	const { cart } = state;

	let productsInCart = cart.products.length || 0;
	if (productsInCart)
		productsInCart = cart.products.reduce((acc, p) => {
			acc = acc + parseInt(p.qty);
			return acc;
		}, 0);

	return (
		<div className={classes.root}>
			<AppBar position="fixed" elevation={0} className={classes.appBar}>
				<Container>
					<Toolbar className={classes.toolBar}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<NextLink href="/">
									<a>
										<React.Fragment>
											<span className={classes.logoWrapper} />
											<img
												src="/assets/logo_tc.png"
												alt="The Code Cosmetics"
												className={classes.logo}
											/>
										</React.Fragment>
									</a>
								</NextLink>
							</Grid>
							<Grid item xs={6}>
								<List className={classes.listStyle}>
									{menu.map(({ url, anchorLinks, nameInMenu }, i) => (
										<CustomMenuItem
											url={url}
											name={nameInMenu}
											key={`${name}-${i}`}
											className={classes.menuItem}
											anchorLinks={anchorLinks}
										/>
									))}
								</List>
							</Grid>

							<Grid item container justifyContent="center" xs={1} direction="column">
								<NextLink href="/cart">
									<a>
										<div style={{ color: 'white' }}>
											<SvgIcon style={{ paddingBottom: '2px', paddingTop: '6px' }}>
												<ShoppingBag style={{ color: 'white' }} />
											</SvgIcon>
											{productsInCart > 0 && (
												<span style={{ color: 'white', position: 'absolute' }}>
													{productsInCart}
												</span>
											)}
										</div>
									</a>
								</NextLink>
							</Grid>
						</Grid>
					</Toolbar>
				</Container>
			</AppBar>
			{movingBanners && <MovingBanner {...movingBanners} />}
		</div>
	);
}
