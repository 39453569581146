import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import Microphone from '@mui/icons-material/Mic';
import Image from 'next/image';
import Grid from '@material-ui/core/Grid';
import { Container, Typography, List, MenuItem, SvgIcon, Button } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';

import ScrollToTopBox from '../scrollToTopBox';
import PaymentLogos from './paymentLogos';

import { Context } from '../../context/appContext';
import getTranslatedContent from '../../staticPageScripts/getTranslatedContent';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(5),
		backgroundColor: theme.palette.footer.background
	},
	footerMenuItem: {
		paddingLeft: 0,
		color: theme.palette.text.secondary,
		'&:hover': {
			backgroundColor: 'inherit'
		}
	},
	logo: {
		maxHeight: '3em',
		height: '3em',
		width: '6em',
		display: 'block'
	},
	shopName: {
		marginLeft: '2.5rem',
		fontSize: '80%',
		marginBottom: '3px',
		display: 'inline-block',
		[theme.breakpoints.down('md')]: {
			fontSize: '70%',
			marginLeft: '1rem'
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '80%',
			marginLeft: '1rem'
		}
	},
	icons: {
		fontSize: '1.85rem',
		marginBottom: '2px',
		borderRadius: '2px',
		backgroundColor: 'black'
	},
	shippingInfo: {
		color: 'white'
	}
}));

const FooterMenuItem = ({ url, name }) => (
	<NextLink href={url} prefetch={false} passHref>
		<a style={{ textDecoration: 'none', color: 'white' }}>
			<MenuItem style={{ paddingLeft: 0 }}>
				<span style={{ fontSize: '0.8em' }}>{name}</span>
			</MenuItem>
		</a>
	</NextLink>
);

const Consent = ({ content }) => (
	<Button
		style={{ color: 'white', padding: 0, paddingTop: '8px', textTransform: 'none', fontSize: '90%' }}
		data-test="open-consent-modal"
		onClick={() => window.showConsentModal(true)}
	>
		{content}
	</Button>
);

const MobileFooterLinks = ({ classes, menus, consentContent }) => (
	<React.Fragment>
		<List component="nav">
			{menus.map(([ ...items ], i) => (
				<React.Fragment key={`${i}`}>
					{items.map(({ name, url, nameInMenu }, i) => (
						<FooterMenuItem
							url={url}
							name={nameInMenu || name}
							key={`${i}`}
							className={classes.footerMenuItem}
						/>
					))}
				</React.Fragment>
			))}
		</List>
		<Consent content={consentContent} />
	</React.Fragment>
);

const DesktopFooterLinks = ({ classes, menus, consentContent }) => (
	<React.Fragment>
		{menus.map(([ ...items ], i) => (
			<Grid key={`${i}`} item sm={i === 0 ? 3 : 2} md={2}>
				<div style={{ marginTop: theme.spacing(9) }} />
				<List component="nav">
					{items.map(({ name, url, nameInMenu }, i) => (
						<FooterMenuItem
							url={url}
							name={nameInMenu || name}
							key={`${i}`}
							className={classes.footerMenuItem}
						/>
					))}
					{i === 0 && <Consent content={consentContent} />}
				</List>
			</Grid>
		))}
	</React.Fragment>
);

export default function Footer({ isMobile, isTablet, menus }) {
	const footerMenu = [
		menus.policyMenu,
		menus.siteMenus.find((m) => m.name.toLowerCase().includes('footer')).fields
	];

	const classes = useStyles(theme);
	const { state } = useContext(Context);
	const { shopData } = state;

	const router = useRouter();

	const translations = getTranslatedContent('translations', router.locale);

	return (
		<div className={classes.root} id="footer">
			<ScrollToTopBox />
			<Container>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={4}>
						<div style={{ paddingRight: theme.spacing(isTablet ? 0 : 10) }}>
							<NextLink href="/">
								<a>
									<React.Fragment>
										<span className={classes.logoWrapper} />
										<Image
											src={'/assets/logo_thecode_weiß_tc.png'}
											alt={'The Code Cosmetics'}
											height={'40px'}
											width={'80px'}
										/>
									</React.Fragment>
								</a>
							</NextLink>

							<br />
							<br />

							<Typography variant="body2" component="p" color="textSecondary">
								{translations.footerText}
							</Typography>

							<br />

							<div>
								<a href={translations.footerFacebookLink} title={translations.footerFacebookTitle}>
									<SvgIcon className={classes.icons} style={{ color: 'white', padding: '2px' }}>
										<FacebookIcon style={{ color: 'white' }} />
									</SvgIcon>
								</a>
								<a href={translations.footerInstagramLink} title={translations.footerInstagramTitle}>
									<span style={{ paddingLeft: '1rem' }}>
										<SvgIcon className={classes.icons} style={{ color: 'white', padding: '1px' }}>
											<InstagramIcon style={{ color: 'white' }} />
										</SvgIcon>
									</span>
								</a>
								<a href={translations.footerPodcastLink} title={translations.footerPodcastTitle}>
									<span style={{ paddingLeft: '1rem' }}>
										<SvgIcon className={classes.icons} style={{ color: 'white', padding: '3px' }}>
											<Microphone style={{ color: 'white' }} />
										</SvgIcon>
									</span>
								</a>
								{/* {<a href={translations.footerYouTubeLink} title={translations.footerYouTubeTitle}>
                                    <span style={ { paddingLeft: '1rem', }}>
                                        <SvgIcon className={classes.icons} style={{ color: 'white', padding: '0 2px', }}>
                                            <YoutubeIcon style={{ color: 'white', }} />
                                        </SvgIcon>
                                    </span>
                                </a>} */}
							</div>
						</div>

						<br />
						<br />

						<div>
							<PaymentLogos />
						</div>

						<div className={classes.shippingInfo}>Kostenloser Versand & 30 Tage Geld-zurück-Garantie</div>

						<div style={{ paddingRight: theme.spacing(isTablet ? 0 : 10) }} />
					</Grid>

					<Grid item xs={false} sm={false} md={1} style={{ padding: 0 }} />

					{isMobile ? (
						<Grid item xs={12} sm={4}>
							<MobileFooterLinks
								classes={classes}
								menus={footerMenu}
								consentContent={translations.cookieSettings}
							/>
						</Grid>
					) : (
						<DesktopFooterLinks
							classes={classes}
							menus={footerMenu}
							consentContent={translations.cookieSettings}
						/>
					)}

					<Grid item xs={12} sm={3} md={2}>
						<div style={{ marginTop: theme.spacing(2.4) }} />
						<Typography variant="h6" component="p" color="textSecondary">
							{translations.contactUs}
						</Typography>
						<div style={{ marginTop: theme.spacing(4.3) }} />
						<Typography variant="body2" component="p" color="textSecondary">
							{shopData.name}
						</Typography>
						<Typography variant="body2" component="p" color="textSecondary">
							{shopData.address1}
						</Typography>
						<Typography variant="body2" component="p" color="textSecondary">
							{shopData.zip} {shopData.city}
						</Typography>
						<br />
						<Typography variant="body2" component="p" color="textSecondary">
							{shopData.email}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<div style={{ fontSize: '80%', color: 'white' }}>
							&copy; {new Date().getFullYear()} {shopData.name}
						</div>
					</Grid>
				</Grid>
			</Container>
			{isMobile && <div style={{ height: '5vh' }} />}
		</div>
	);
}
