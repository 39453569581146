import { createTheme } from '@material-ui/core/styles';

// eslint-disable-next-line no-restricted-imports
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import { red } from '@material-ui/core/colors';

const breakpoints = createBreakpoints({});

// Create a theme instance.
const theme = createTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            xs_m: 600,
            sm: 768,
            md: 960,
            lg: 1280,
        },
    },
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#fff',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
            black: 'black',
            gray: 'gray',
        },
        navigation: {
            background: 'black',
            textColor: '#fff',
        },
        footer: {
            background: 'black',
            textColor: '#fff',
        },
        text: {
            white: 'white',
            secondary: 'white',
            navigation: '#263238',
        },
    },
    typography: {
        fontFamily: [
            'OptimaLTStd',
            'OptimaLTStd',
            'OptimaLTStd-ExtraBlack',
            'OptimaLTStd-MediumItalic',
            'serif'
        ].join(','),
        subtitle1: {
            fontFamily: [
                'Magnite',
                'cursive'
            ].join(','),
            fontSize: '4rem',
            lineHeight: '1.5',
            [breakpoints.only('xs')]: {
                fontSize: '3.5rem',
            },
        },
        subtitle2: {
            fontSize: '2rem',
            fontFamily: [
                'OptimaLTStd-MediumItalic'
            ].join(','),
        },
        h1: {
            fontSize: '3rem',
            marginBottom: '1.25rem',
            [breakpoints.only('xs')]: {
                fontSize: '2.3rem',
            },
            fontFamily: [
                'OptimaLTStd'
            ].join(','),
        },
        h2: {
            fontSize: '2.5rem',
            marginBottom: '1.25rem',
            [breakpoints.only('xs')]: {
                fontSize: '2rem',
            },
            fontFamily: [
                'OptimaLTStd'
            ].join(','),
        },
        h3: {
            fontSize: '1.8rem',
            marginBottom: '1.25rem',
            fontFamily: [
                'OptimaLTStd'
            ].join(','),
        },
        h4: {
            fontSize: '1.5rem',
            marginBottom: '1.25rem',
            fontFamily: [
                'OptimaLTStd'
            ].join(','),
        },
        h5: {
            fontSize: '1.2rem',
            marginBottom: '1.25rem',
            fontFamily: [
                'OptimaLTStd'
            ].join(','),
        },
        h6: {
            fontSize: '1.1rem',
            marginBottom: '1.25rem',
            fontFamily: [
                'OptimaLTStd'
            ].join(','),
        },
        caption: {
            fontFamily: [
                'OptimaLTStd'
            ].join(','),
        },
        body1: {
            fontSize: '1rem',
            fontFamily: [
                'OptimaLTStd'
            ].join(','),
        },
        body2: {
            fontSize: '0.8rem',
            fontFamily: [
                'OptimaLTStd'
            ].join(','),
        },
    },
    overrides: {
        MuiButton: {
            textSizeSmall: {
                fontSize: '0.9rem',
            },
            textSizeLarge: {
                fontSize: '1rem',
            },
        },
    },
});

export default theme;
