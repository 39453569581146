import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction, SvgIcon } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import { useRouter } from 'next/router';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingCart';

import { Context } from '../../context/appContext';

import theme from '../../theme';
import MobileSidebar from './mobileSidebar';

const useStyles = makeStyles({
    root: {
        backgroundColor: theme.palette.navigation.background,
        color: theme.palette.navigation.textColor,
        position: 'fixed',
        width: '100vw',
        bottom: 0,
        zIndex: 500,
    },
});

export default function FooterBottomNavigation ({ menus, }) {
    const classes = useStyles();
    const router = useRouter();
    const [showSidebar, setShowSidebar] = React.useState(false);

    const { state, } = React.useContext(Context);
    const { cart, } = state;

    let productsInCart = cart.products.length || 0;
    if (productsInCart) productsInCart = cart.products.reduce((acc, p) => {
        acc = acc + parseInt(p.qty);
        return acc;
    }, 0);

    const scrollToTop = () => window.scrollTo({ top: 1, behavior: 'smooth', });

    const handleNavClick = (newValue) => {
        switch (newValue) {
        case 0:
            router.push('/');
            scrollToTop();
            break;
        case 1:
            router.push('/cart');
            scrollToTop();
            break;
        case 2:
            setShowSidebar(!showSidebar);
            break;
        default:
            break;
        }
    };

    return (
        <React.Fragment>
            <BottomNavigation
                showLabels={false}
                className={classes.root}
                onChange={(_, newValue) => handleNavClick(newValue)}
            >
                <BottomNavigationAction label="Home" icon={<HomeIcon />} />
                <BottomNavigationAction label="Cart" icon={
                    <React.Fragment>
                        <SvgIcon style={{ padding: '0 2px', backgroundColor: 'black', }}>
                            <ShoppingBasketIcon />
                        </SvgIcon>
                        {productsInCart > 0 && <span style={{ color: 'white', position: 'absolute', paddingLeft: theme.spacing(5), }}>{productsInCart}</span>}
                    </React.Fragment>
                } />
                <BottomNavigationAction onChange={() => setShowSidebar(!showSidebar)} label="Menu" id="openMobileSidebar" icon={<MenuIcon />} />
            </BottomNavigation>
            {showSidebar && <MobileSidebar open={showSidebar} toggleOpen={setShowSidebar} menus={menus} />}
        </React.Fragment>
    );
}
