import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import theme from '../theme';

const Spacer = ({ spaceDesktop, spaceTablet, spaceMobile = 0, }) => {
    let spacerHeight = spaceMobile;

    const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    if ((spaceTablet || spaceTablet === 0) && isTablet) spacerHeight = spaceTablet;
    if ((spaceDesktop || spaceDesktop === 0) && isDesktop) spacerHeight = spaceDesktop;

    if (parseInt(spacerHeight) < 1) return null;

    return <div style={{ display: 'inline-block', width: '100%', height: `${parseInt(spacerHeight)}vh`, }} />;
};

export default Spacer;
