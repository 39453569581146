import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, TextField, Container, useMediaQuery } from '@material-ui/core';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import Modal from './modal';
import theme from '../theme';
import Button from './button';
import ErrorSnackBar from './snackbars/error';
import handleNewsletterRegistration from '../utils/handleNewsletterRegistration';

import getTranslatedContent from '../staticPageScripts/getTranslatedContent';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
        },
    },
    form: {
        marginBottom: theme.spacing(3),
    },
    registerForm: {
        height: '100%',
        width: '100%',
        margin: 'auto',
    },
}));

const getModalBody = (text) => {
    return (
        <div style={{ textAlign: 'center', padding: '75px', overflow: 'auto', }}>
            <Typography variant="h5" component="p">
                {text}
            </Typography>
        </div>
    );
};

export default function NewsletterRegistration (props) {
    const router = useRouter();
    const classes = useStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const translations = getTranslatedContent('translations', router.locale);

    const [emailAddress, setEmailAddress] = React.useState(undefined);
    const [error, setShowError] = React.useState(undefined);
    const [signupSuccess, setSignupSuccess] = React.useState(undefined);

    const handleClose = (_, reason) => {
        if (reason !== 'clickaway') {
            setShowError(undefined);
            setSignupSuccess(undefined);
        }
    };

    const changeEmailAddress = (e) => {
        setEmailAddress(e.target.value);
    };

    const handleRegistration = async () => {
        await handleNewsletterRegistration(emailAddress, setSignupSuccess, setShowError, router.locale);
        const ele = document.getElementById('newsletterEmailInput');
        if (ele) {
            ele.value = null;
            setEmailAddress(null);
        }
    };

    React.useEffect(() => {
        if (signupSuccess && props.redirectPage) {
            router.push(window.location.origin + '/' + props.redirectPage.replace('/', ''));
        }
    }, [signupSuccess]);

    return (
        <div style={{ backgroundColor: theme.palette.background.footer, }}>
            <Container >
                <Grid container className={classes.root}>
                    {props.showText && (
                        <React.Fragment>
                            <Grid item xs={12} sm={5}>
                                <Typography variant="h5" component="p">
                                    {translations.newsletterHeadline}
                                </Typography>
                                <Typography variant="body1">
                                    {translations.newsletterText}
                                    <span> </span>
                                    <NextLink href="/privacy-policy">
                                        <a style={{ textDecoration: 'none', }}>
                                            {translations.newsletterPolicyText}
                                        </a>
                                    </NextLink>
                                </Typography>
                            </Grid>
                            <Grid item xs={false} sm={1} md={false} style={{ padding: 0, }} />
                        </React.Fragment>
                    )}
                    <Grid item container xs={12} sm={6} className={classes.registerForm}>
                        <Grid item xs={12} md={6}>
                            <form
                                noValidate
                                autoComplete="email"
                                className={classes.form}
                                id="newsletterRegistrationForm"
                                onSubmit={e => e.preventDefault()}
                            >
                                <TextField
                                    error={Boolean(error)}
                                    label={translations.insertEmailAddressFooter}
                                    type="email"
                                    id="newsletterEmailInput"
                                    fullWidth
                                    onChange={changeEmailAddress}
                                    variant="standard"
                                    autoComplete="on"
                                    helperText={error || ''}
                                    InputLabelProps={{
                                        style: {
                                            color: 'gray',
                                        },
                                    }}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={false} sm={false} md={1} style={{ padding: 0, }} />
                        <Grid item xs={12} md={5}>
                            <div style={{ marginTop: theme.spacing(1), }} />
                            <Button onClickFunction={handleRegistration} fullWidth type="black">{translations.newsletter_register_button}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {(error) && (
                    <ErrorSnackBar errorMessage={error} handleClose={handleClose} />
                )}
                {(signupSuccess && !props.redirectPage) && (
                    <Modal
                        open
                        modalHeight={isMobile ? '60vh' : '30vh'}
                        modalWidth={isMobile ? '80vw' : '50vw'}
                        body={getModalBody(signupSuccess)} handleClose={handleClose} />
                )}
            </Container>
        </div>
    );
}
