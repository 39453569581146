import React from 'react';

export default function useLocalStorage (key, initialValue = null) {
    const [ storedValue, setStoredValue ] = React.useState(() => {
        try {
            if (!process.browser) return initialValue;
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(error);
        }
    };

    return [ storedValue, setValue ];
}
