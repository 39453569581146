import logger from './logger';
import validateEmailAddress from './validateEmailAddress';

import getTranslatedContent from '../staticPageScripts/getTranslatedContent';

const handleRegistration = async (emailAddress, successHandler, errorHandler, locale = 'de') => {
    const translations = getTranslatedContent('translations', locale);

    if (validateEmailAddress(emailAddress)) {
        const response = await fetch('/api/email/createContact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ emailAddress, registration: true, locale, }),
        }).then(r => r.json());

        if (response.message && (response.message === 'ok' || response.id)) {
            successHandler(translations.newsletter_doi_success);
        } else if (response.message && response.message.error && response.error.response.text && response.error.response.text.includes('duplicate_parameter')) {
            successHandler(translations.newsletter_doi_success);
        } else {
            logger('error', 'handleRegistration', response);
            errorHandler(translations.newsletter_error);
        }

        return response;
    } else {
        logger('error', 'handleRegistration - invalid email');
        errorHandler(translations.invalidEmail);
    }
};

export default handleRegistration;
