/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, ListItem, ListItemText, List } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '70vw',
		height: '80vh'
	},
	paper: {
		borderLeft: '1px solid gray',
		color: theme.palette.navigation.textColor,
		backgroundColor: theme.palette.navigation.background
	},
	list: {
		width: '75vw',
		zIndex: 900
	},
	menu: {
		position: 'absolute',
		height: '80vh',
		bottom: '1vh'
	},
	listItem: {
		paddingBottom: theme.spacing(3)
	},
	link: {
		color: theme.palette.navigation.textColor,
		textDecoration: 'none'
	},
	closeIcon: {
		position: 'fixed',
		bottom: '5%',
		right: '10%'
	},
	openIcon: {
		transform: 'rotate(180deg)'
	}
}));

const LinkComponent = ({ url, name, i, classes, noMargin }) => (
	<Link href={url} key={`${name}-${i}`}>
		<a className={classes.link}>
			<ListItem button key={name} className={noMargin ? '' : classes.listItem}>
				<ListItemText primary={name} />
			</ListItem>
		</a>
	</Link>
);

export default function Sidebar({ open, toggleOpen, menus }) {
	const menu = menus.siteMenus.find((m) => m.name.toLowerCase().includes('main')).fields;
	const menuItems = menu.map((item) => item.name);

	const classes = useStyles(theme);
	const [ openMenu, setOpenMenu ] = React.useState(false);

	const handleClick = (ele) => {
		if (openMenu === ele) setOpenMenu(false);
		else setOpenMenu(ele);
		if (menuItems.includes(ele)) {
			toggleOpen(false);
		}
	};

	return (
		<Drawer open={open} anchor="right" classes={{ paper: classes.paper }}>
			<div className={classes.list}>
				<div className={classes.menu}>
					<List component="nav" className={classes.root} aria-labelledby="mobile navigation">
						{menu.map(({ url, anchorLinks, nameInMenu }, i) => {
							if (!nameInMenu || !url) return null;
							const hasSubMenu = false; // Boolean(anchorLinks && anchorLinks.length);
							return (
								<React.Fragment key={`${nameInMenu}-${i}`}>
									<ListItem button onClick={() => handleClick(nameInMenu)}>
										<LinkComponent name={nameInMenu} url={url} i={i} classes={classes} noMargin />
									</ListItem>
									{hasSubMenu &&
										anchorLinks.map((l) => {
											let link = `${url}`;
											// do not add an anchor link to the first link element
											if (i !== 0) link += `#${encodeURI(l.name)}`;
											return (
												<div key={`${l.id}-${i}`} style={{ paddingLeft: '2rem' }}>
													<LinkComponent i={i} url={link} name={l.name} classes={classes} />
												</div>
											);
										})}
								</React.Fragment>
							);
						})}
					</List>
				</div>

				<div className={classes.closeIcon} id="closeMobileSidebar" onClick={() => toggleOpen(false)}>
					<CloseIcon />
				</div>
			</div>
		</Drawer>
	);
}
