import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, Grid, Paper, Typography, TextField, CircularProgress, useMediaQuery } from '@material-ui/core';
import { useRouter } from 'next/router';

import theme from '../../theme';
import Spacer from '../spacer';
import TCButton from '../button';

import handleNewsletterRegistration from '../../utils/handleNewsletterRegistration';
import getTranslatedContent from '../../staticPageScripts/getTranslatedContent';

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,g_xy_center,q_auto/';

const useStyles = makeStyles((theme) => ({
    paper: {
        overflow: 'auto',
        outline: 'none !important',
        backgroundColor: theme.palette.background.paper
    },
    closeButton: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(3),
        border: '2px solid black',
        padding: 0,
        zIndex: 100000,
        position: 'absolute',
        right: 0,
        [theme.breakpoints.up('lg')]: {
            right: '20vw'
        }
    },
    textPaper: {
        textAlign: 'center',
        boxShadow: 'none',
        marginTop: '20%',
        padding: theme.spacing(0, 5)
    },
    form: {
        paddingTop: theme.spacing(3)
    }
}));

export default function NewsletterModal({ open, successText, headline, text, image }) {
    const classes = useStyles(theme);
    const router = useRouter();

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const translations = getTranslatedContent('translations', router.locale);

    const [showModal, setShowModal] = React.useState(open);
    const [emailAddress, setEmailAddress] = React.useState(undefined);
    const [error, setShowError] = React.useState(undefined);
    const [signupSuccess, setSignupSuccess] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);

    let modalHeight = '50vh';
    let modalWidth = '60vw';

    if (!isDesktop) {
        modalHeight = '50vh';
        modalWidth = '100vw';
    }

    const changeEmailAddress = (e) => {
        setEmailAddress(e.target.value);
    };

    const handleRegistration = async () => {
        setLoading(true);

        await handleNewsletterRegistration(emailAddress, setSignupSuccess, setShowError, router.locale);

        const ele = document.getElementById('newsletterEmailInput');
        if (ele) {
            ele.value = null;
            setEmailAddress(null);
        }

        setLoading(false);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    React.useEffect(() => {
        window.localStorage.setItem('newsletterModalClosed', true);
    }, []);

    React.useEffect(
        () => {
            if (showModal) {
                document.body.style.overflow = 'hidden';
                document.documentElement.style.overflow = 'hidden';
                document.documentElement.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
                document.documentElement.style.overflow = 'auto';
                document.documentElement.overflow = 'auto';
            }
            return function cleanup() {
                document.body.style.overflow = 'auto';
                document.documentElement.style.overflow = 'auto';
                document.documentElement.overflow = 'auto';
            };
        },
        [showModal]
    );

    const bodyWrapper = (
        <div className={classes.paper} style={{ height: modalHeight, width: modalWidth, margin: theme.spacing(1, 1) }}>
            <Button className={classes.closeButton} onClick={handleClose}>
                <strong>X</strong>
            </Button>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Paper className={classes.textPaper}>
                        <Typography variant="h2" component="p">
                            {headline}
                        </Typography>
                        <Spacer spaceMobile={1} />
                        <Typography variant="body2" component="p">
                            {text}
                        </Typography>
                        {signupSuccess && (
                            <React.Fragment>
                                <Spacer spaceMobile={3} />
                                <Typography>{successText}</Typography>
                            </React.Fragment>
                        )}
                        {!signupSuccess && (
                            <React.Fragment>
                                <div className={classes.form}>
                                    <form
                                        noValidate
                                        autoComplete="email"
                                        id="newsletterRegistrationForm"
                                        onSubmit={(e) => e.preventDefault()}
                                    >
                                        <TextField
                                            error={Boolean(error)}
                                            label={translations.insertEmailAddressFooter}
                                            type="email"
                                            id="newsletterEmailInput"
                                            fullWidth
                                            onChange={changeEmailAddress}
                                            variant="standard"
                                            autoComplete="on"
                                            helperText={error || ''}
                                            InputLabelProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                        />
                                    </form>
                                    <Spacer spaceMobile={1} />
                                    {loading ? (
                                        <CircularProgress color="inherit" />
                                    ) : (
                                        <TCButton onClickFunction={handleRegistration} fullWidth type="black">
                                            {translations.newsletter_register_button}
                                        </TCButton>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={false} sm={6}>
                    <div
                        style={{
                            zIndex: -1,
                            width: '100%',
                            height: modalHeight,
                            backgroundSize: 'cover',
                            backgroundImage: `url(${base}${image})`
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );

    return (
        <React.Fragment>
            <Modal
                open={showModal}
                onClose={handleClose}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                {bodyWrapper}
            </Modal>
        </React.Fragment>
    );
}
