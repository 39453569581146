import React from 'react';
import { Context } from '../context/appContext';
import logger from '../utils/logger';

/**
 * This component handles the synchronization between the app and shopify
 * It checks if a cart is stored in the localStorage. If yes
 * it will fetch the checkout from shopify to check if the checkout is still active
 */
export default function ShopifySync() {
    const { state, updateState, } = React.useContext(Context);

    const updateStateHandler = (cart) => {
        const newState = { ...state, };
        newState.cart = cart;
        updateState(newState);
    };

    const syncCartStateWithShopify = async (cart) => {
        try {
            const shopifyCheckout = await fetch(window.location.origin + '/api/shop/getCheckout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ checkoutId: cart.shopifyCheckoutToken, }),
            }).then(r => r.json());

            // there was an error, remove the cart
            if (!shopifyCheckout || shopifyCheckout.error) {
                window.localStorage.removeItem('cart');
                window.localStorage.removeItem('quiz');
            } else if (shopifyCheckout && shopifyCheckout.checkout && shopifyCheckout.checkout.order && shopifyCheckout.checkout.order && shopifyCheckout.checkout.order.orderNumber) {
                // we have an order - so we can delete the cart and the quiz data
                window.localStorage.removeItem('cart');
                window.localStorage.removeItem('quiz');
            } else {
                updateStateHandler(cart);
            }
        } catch (error) {
            const message = error.message || error;
            console.error(message);
            logger('error', 'syncCartStateWithShopify - try catch', message);
            // if there is an error, we simply remove the cart and the quiz
            window.localStorage.removeItem('cart');
            window.localStorage.removeItem('quiz');
        }
    };

    React.useEffect(() => {
        // Sync with Shopify
        if (window.localStorage.getItem('cart')) {
            try {
                const cart = JSON.parse(window.localStorage.getItem('cart'));
                // if we have a shopifyCheckoutToken stored in the cart, we must check shopify
                if (cart && cart.shopifyCheckoutToken) syncCartStateWithShopify(cart);
                // otherwise we simply can add the cart to the context
                else updateStateHandler(cart);
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    return <span />;
}
