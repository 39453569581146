import React from 'react';
import Marquee from 'react-fast-marquee';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import theme from '../../theme';

const useStyles = makeStyles(() => ({
    container: {
        height: '40px',
        width: '100vw',
        [theme.breakpoints.up('md')]: {
            marginTop: '65px',
        },
    },
    item: {
        width: '100vw',
        [theme.breakpoints.up('md')]: {
            width: '35vw',
        },

    },
}));

const movingBanner = ({ text, active, hexColorCode, }) => {
    const classes = useStyles();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    if (!active) return null;

    return (
        <div className={classes.container} style={{ backgroundColor: hexColorCode, }}>
            <Marquee speed={50} gradient={false}>
                <p className={classes.item}>+++ {text} +++</p>
                {matches && <p className={classes.item}>+++ {text} +++</p>}
                {matches && <p className={classes.item}>+++ {text} +++</p>}
            </Marquee>
        </div>
    );
};

export default movingBanner
;
