var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ecb480793990b9a5e91c57fa33079b97837dad6c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: SENTRY_DSN || 'https://853b9ee988f54bed811825952897f0c4@o1298750.ingest.sentry.io/6530044',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%.
    replaysSessionSampleRate: process.env.NODE_ENV === 'development' ? 0 : 0.1,
    // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
        // Modify the event here
        if (event.user) {
            // Don't send user's email address
            delete event.user.email;
            // delete user ip if it exists
            if (event.user.ip) delete event.user.ip;
        }
        return event;
    }
});
