import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';

import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import useLocalStorage from '../hooks/useLocalStorage';
import { useMediaQuery } from '@material-ui/core';

import theme from '../theme';
import Button from '../components/button';

import getTranslatedContent from '../staticPageScripts/getTranslatedContent';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    modal: {
        display: 'flex',
        overflow: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        width: '95vw',
        [theme.breakpoints.up('lg')]: {
            width: '75vw',
        },
    },
    paper: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.black,
        border: '1px solid white',
        boxShadow: theme.shadows[5],
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(12),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(4),
        },
        '&:focus': {
            outline: 'none',
        },
    },
    contents: {
        justifyContent: 'space-between',
    },
    button1: {
        order: 2,
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
    },
    button2: {
        order: 1,
        [theme.breakpoints.up('sm')]: {
            order: 2,
        },
    },
    textWhite: {
        color: theme.palette.secondary.main,
    },
}));

export default function ConsentModal () {
    const router = useRouter();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const translations = getTranslatedContent('translations', router.locale);

    const [ open, setOpen ] = React.useState(false);
    const [ state, setState ] = useLocalStorage('consent');

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked, });
    };

    const handleClose = () => {
        setOpen(false);
        router.push(window.location.href);
    };

    const selectAll = () => {
        const newState = {
            required: true,
            statistic: true,
            marketing: true,
        };
        setState(newState);
        setOpen(false);
    };

    React.useEffect(() => {
        if (open) {
            document.body.style.position = 'fixed';
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.documentElement.overflow = 'hidden';
        } else {
            document.body.style.position = 'relative';
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
            document.documentElement.overflow = 'auto';
        }
        return function cleanup () {
            document.body.style.position = 'relative';
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
            document.documentElement.overflow = 'auto';
        };
    }, [open]);

    // Add the google tag manager script to the page if the user have consent
    React.useEffect(
        () => {
            const domElement = document.getElementById('googletagmanager');
            if (!domElement) return null;
            if (!open && state && (state.statistic || state.marketing)) {
                const gtmScript = `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${process.env.GTM}');
                `;
                domElement.innerHTML = gtmScript;
            }
            if (!open && state && !state.statistic && !state.marketing) {
                domElement.innerHTML = '';
                window.dataLayer = null;
            }
            if (!state) {
                setState({
                    required: true,
                    statistic: false,
                    marketing: false,
                });
                setOpen(true);
            }
            if (!window.showConsentModal) {
                window.showConsentModal = setOpen;
            }
        },
        [ state, open ]
    );

    if (!state) return null;

    return (
        <Modal
            aria-labelledby="Tracking Consent"
            aria-describedby="Cookies & Technologien erlauben?"
            data-test="consent-modal"
            className={classes.modal}
            open={open}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <h2 id="Tracking Consent">{translations.consent_modal_headline}</h2>
                    <p id="Cookies & Technologien erlauben?">
                        {translations.consent_modal_text}
                        <span> </span>
                        <a className={classes.textWhite} title={translations.consent_modal_link_to_data_policy} href="/privacy-policy" target="_blank">
                            {translations.consent_modal_link_to_data_policy}
                        </a>
                    </p>
                    <FormGroup row className={classes.contents}>
                        <Grid container justifyContent="space-evenly" spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControlLabel
                                    control={<Checkbox color="default" checked={state.required} name={translations.consent_modal_confirm_necessary} />}
                                    label={translations.consent_modal_confirm_necessary}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControlLabel
                                    control={<Checkbox color="default" checked={state.statistic} onChange={handleChange} name={'statistic'} />}
                                    label={translations.consent_modal_confirm_statistics}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <FormControlLabel
                                    control={<Checkbox color="default" checked={state.marketing} onChange={handleChange} name={'marketing'} />}
                                    label={translations.consent_modal_confirm_marketing}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} className={classes.button1}>
                                <Button showIcon={false} fullWidth={!isMobile} buttonStyle={{ width: isMobile ? '80vw' : '100%', }} type="blackButton" variant="outlined" onClickFunction={handleClose} >
                                    <span data-test="consent-modal-submit">{translations.consent_modal_confirm_selection}</span>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} className={classes.button2}>
                                <Button type={'white'} showIcon={false} fullWidth={!isMobile} buttonStyle={{ width: isMobile ? '80vw' : '100%', }} variant="contained" onClickFunction={selectAll}>
                                    {translations.consent_modal_confirm_all}
                                </Button>
                            </Grid>
                        </Grid>
                    </FormGroup>
                </div>
            </Fade>
        </Modal>
    );
}
