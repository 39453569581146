import React, { useState, createContext } from 'react';

import useLocalStorage from '../hooks/useLocalStorage';
import getTranslatedContent from '../staticPageScripts/getTranslatedContent';

const initialState = {
    cart: {
        products: [],
    },
};

const Context = createContext({});

const getCartFromLocalStorage = () => {
    try {
        const cart = localStorage.getItem('cart');
        if (cart) return JSON.parse(cart);
        return initialState.cart;
    } catch (e) {
        return initialState.cart;
    }
};

const Provider = ({ children, locale, }) => {
    const translations = getTranslatedContent('translations', locale);
    const shopData = getTranslatedContent('shopData', locale);

    const [state, setNewState] = useState({
        ...initialState,
        cart: getCartFromLocalStorage(),
        language: translations.language.english,
        shopData,
    });

    const [, setCart] = useLocalStorage('cart', getCartFromLocalStorage());

    const updateState = (newState) => {
        setNewState({ ...state, ...newState, });
        if (newState.cart) setCart(newState.cart);
    };

    const value = { state, updateState, };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };
