import React from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import theme from '../../theme';

const useStyles = makeStyles(theme => ({
    paper: {
        background: theme.palette.navigation.background,
        borderRadius: 0,
        border: '1px solid gray',
        color: 'white',
        boxShadow: '0',
        marginTop: '0.2rem',
        width: '200px',
    },
    menuItems: {
        marginTop: '3%',
        '&:hover': {
            backgroundColor: '#312e2e',
        },
    },
    activeElement: {
        borderBottom: '1px solid white',
        marginBottom: '2px',
        display: 'inline-block',
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.navigation.textColor,
    },
}));

const CustomMenuItem = ({ name, url, className, anchorLinks, }) => {
    const classes = useStyles(theme);
    const hasSubMenu = Boolean(anchorLinks && anchorLinks.length);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => setAnchorEl(null);
    const handleClick = event => setAnchorEl(event.currentTarget);

    return (
        <React.Fragment >
            {hasSubMenu ? (
                <MenuItem className={className} onClick={handleClick}>
                    <span style={{ fontSize: '1rem', }} >{name}</span>
                    <ArrowDropDownIcon fontSize={'large'} style={{ marginLeft: '5%', paddingBottom: '3px', }} />
                </MenuItem>
            )
                : (
                    <MenuItem >
                        <Link href={url} passHref>
                            <a className={classes.link}>
                                <span style={{ fontSize: '1rem', }} >{name}</span>
                            </a>
                        </Link>
                    </MenuItem>
                )}
            <Menu
                keepMounted
                style={{ marginTop: '1.6rem', }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{
                    paper: classes.paper,
                }}
            >
                {hasSubMenu && anchorLinks.map((l, i) => {
                    let link = `${url}`;
                    // do not add an anchor link to the first link element
                    if (i !== 0) link += `#${encodeURI(l.name)}`;
                    return (
                        <div key={l.id}>
                            <Link href={link} passHref>
                                <a className={classes.link} >
                                    <MenuItem className={classes.menuItems} onClick={handleClose}>{l.name}</MenuItem>
                                </a>
                            </Link>
                        </div>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

export default CustomMenuItem;
