import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import theme from '../theme';

const useStyles = makeStyles(() => ({
    icon: {
        textAlign: 'right',
        marginLeft: '1rem',
        transform: 'rotate(90deg)',
    },
}));

export default function Buttons ({
    children,
    type = 'main',
    variant = 'contained',
    fullWidth = false,
    // eslint-disable-next-line no-empty-function
    onClickFunction = () => {
        //
    },
    buttonStyle = {},
    showIcon = true,
    disabled = false,
    strong = true,
    rootWidth = 'unset',
    ...props
}) {
    const classes = useStyles();

    const whiteButton = { color: 'black', backgroundColor: 'white', textTransform: 'none', borderRadius: 0, };
    const blackButton = { backgroundColor: 'black', color: 'white', textTransform: 'none', border: '1px solid white', borderColor: 'black', borderRadius: 0, };

    const clickHandler = e => onClickFunction(e);

    return (
        <div style={{ width: rootWidth, }}>
            <Button
                onClick={clickHandler}
                fullWidth={fullWidth}
                variant={variant}
                disabled={disabled}
                style={type === 'white' ? { ...whiteButton, ...buttonStyle, } : { ...blackButton, ...buttonStyle, }}
                {...props}
            >
                {strong ? <strong style={{ fontSize: theme.overrides.MuiButton.textSizeSmall.fontSize, }} >{children}</strong> : <span style={{ fontSize: theme.overrides.MuiButton.textSizeSmall.fontSize, }} >{children}</span>}
                {showIcon && <KeyboardArrowUpIcon className={classes.icon} />}
            </Button>
        </div>
    );
}
