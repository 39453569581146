// eslint-disable-next-line @typescript-eslint/no-var-requires
const Sentry = require('@sentry/nextjs');

const logger = (level = 'info', message, context = {}) => {
    if (!message) return null;
    try {
        Sentry.setUser(null);
        Sentry.captureException(new Error(message), {
            tags: {
                level,
                ...context
            },
        });
    } catch (error) {
        console.error(error);
    }
};

module.exports = logger;
